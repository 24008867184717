
import { ref, defineComponent, onMounted, reactive, toRefs, watch } from "vue";
import fixTable from "@/hooks/checking/useFixTable";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
    searchType: {
      type: String,
      required: false,
      default: "nonHistory",
    },
    tabType: {
      type: String,
      required: false,
      default: "",
    },
    height: {
      type: Number,
      required: false,
      default: 471,
    },
  },
  emits: ["open-upload-drawer"],
  setup(props, { emit }) {
    const columnsForNonHistory = [
      {
        title: "项目编号",
        width: 150,
        dataIndex: "programCode",
      },
      {
        title: "项目名称",
        width: 250,
        dataIndex: "programNameCn",
      },
      {
        title: "项目类型",
        width: 200,
        dataIndex: "offerTypeNameCn",
        // slots: { customRender: 'programType' },
      },
      {
        title: "车架号",
        width: 200,
        dataIndex: "vin",
      },
      {
        title: "车型",
        width: 250,
        dataIndex: "modelName",
      },
      {
        title: "年款",
        width: 100,
        dataIndex: "modelYear",
      },
      {
        title: "提交时间",
        width: 150,
        dataIndex: "claimDate",
      },
      {
        title: "检查结果",
        width: 100,
        dataIndex: "checkResultCnText",
      },
      {
        title: "本次沟通金额",
        width: 100,
        dataIndex: "finalBonus",
      },
      {
        title: "已付款金额",
        width: 100,
        dataIndex: "paymentBonus",
      },
      {
        title: "沟通状态",
        width: 100,
        dataIndex: "communicationStatusText",
      },
      {
        title: "确认截止日期",
        width: 150,
        dataIndex: "confirmDeadline",
      },
      {
        title: "本轮结清",
        width: 100,
        dataIndex: "roundClosing",
      },
      {
        title: "最后一次沟通",
        width: 150,
        dataIndex: "lastCommunication",
      },
      {
        title: "申诉状态",
        width: 100,
        dataIndex: "appealStatusText",
      },
      {
        title: "操作",
        width: 150,
        dataIndex: "operation",
        fixed: "right",
        slots: { customRender: "operation" },
      },
    ];
    const columnsForNonHistoryByDeduction = [
      {
        title: "项目编号",
        width: 150,
        dataIndex: "programCode",
      },
      {
        title: "项目名称",
        width: 250,
        dataIndex: "programNameCn",
      },
      {
        title: "项目类型",
        width: 200,
        dataIndex: "offerTypeNameCn",
      },
      {
        title: "车架号",
        width: 200,
        dataIndex: "vin",
      },
      {
        title: "车型",
        width: 250,
        dataIndex: "modelName",
      },
      {
        title: "年款",
        width: 100,
        dataIndex: "modelYear",
      },
      {
        title: "提交时间",
        width: 150,
        dataIndex: "claimDate",
      },
      {
        title: "检查结果",
        width: 100,
        dataIndex: "checkResultCnText",
      },
      {
        title: "本次沟通金额",
        width: 100,
        dataIndex: "finalBonus",
      },
      {
        title: "已付款金额",
        width: 100,
        dataIndex: "paymentBonus",
      },
      {
        title: "沟通状态",
        width: 100,
        dataIndex: "communicationStatusText",
      },
      {
        title: "沟通截止日期",
        width: 150,
        dataIndex: "exemptionDeadline",
      },
      {
        title: "确认截止日期",
        width: 150,
        dataIndex: "confirmDeadline",
      },
      {
        title: "本轮结清",
        width: 100,
        dataIndex: "roundClosing",
      },
      {
        title: "最后一次沟通",
        width: 150,
        dataIndex: "lastCommunication",
      },
      {
        title: "申诉状态",
        width: 100,
        dataIndex: "appealStatusText",
      },
      {
        title: "操作",
        width: 150,
        dataIndex: "operation",
        fixed: "right",
        slots: { customRender: "operation" },
      },
    ];
    const columnsForHistory = [
      {
        title: "项目编号",
        width: 150,
        dataIndex: "programCode",
      },
      {
        title: "项目名称",
        width: 250,
        dataIndex: "programNameCn",
      },
      {
        title: "项目类型",
        width: 200,
        dataIndex: "offerTypeNameCn",
        // slots: { customRender: 'programType' },
      },
      {
        title: "车架号",
        width: 200,
        dataIndex: "vin",
      },
      {
        title: "车型",
        width: 250,
        dataIndex: "modelName",
      },
      {
        title: "年款",
        width: 100,
        dataIndex: "modelYear",
      },
      {
        title: "提交时间",
        width: 150,
        dataIndex: "claimDate",
      },
      {
        title: "检查结果",
        width: 100,
        dataIndex: "checkResultCnText",
      },
      {
        title: "本次沟通金额",
        width: 100,
        dataIndex: "finalBonus",
      },
      {
        title: "已付款金额",
        width: 100,
        dataIndex: "paymentBonus",
      },
      {
        title: "沟通状态",
        width: 100,
        dataIndex: "communicationStatusText",
      },
      {
        title: "确认截止日期",
        width: 150,
        dataIndex: "confirmDeadline",
      },
      {
        title: "本轮结清",
        width: 100,
        dataIndex: "roundClosing",
      },
      {
        title: "最后一次沟通",
        width: 150,
        dataIndex: "lastCommunication",
      },
      {
        title: "申请状态",
        width: 150,
        dataIndex: "statusText",
      },
      {
        title: "申诉状态",
        width: 100,
        dataIndex: "appealStatusText",
      },
      {
        title: "操作",
        width: 150,
        dataIndex: "operation",
        fixed: "right",
        slots: { customRender: "operation" },
      },
    ];
    const state = reactive({
      selectedRowKeys: [],
      selectedRows: [],
    });
    const onSelectChange = (selectedRowKeys: any, selectedRows: any) => {
      state.selectedRowKeys = selectedRowKeys;
      state.selectedRows = selectedRows;
    };
    // const height = 100 + 50 + 16 + 190 + 50 + 65;
    const tableHeight = fixTable(props.height);
    // let tableHeight = ref<number>(0);
    const columns: any = ref([]);

    const router = useRouter();
    const gotoDetail = (record: any) => {
      router.push({
        name: "appealDetail",
        params: {
          claimId: record.dealerClaimAppId,
        },
      });
    };
    const openUploadDrawer = (record: any) => {
      emit("open-upload-drawer", record, "upload");
    };
    const appealOperation = (record: any) => {
      emit("open-upload-drawer", record, "appeal");
    };

    watch(
      () => (props as any).tableData,
      () => {
        state.selectedRows = [];
        state.selectedRowKeys = [];
      }
    );

    onMounted(() => {
      if (props.searchType === "nonHistory") {
        if (props.tabType === "toBeConfirmedDeductionData") {
          columns.value = columnsForNonHistoryByDeduction;
        } else {
          columns.value = columnsForNonHistory;
        }
      }
      if (props.searchType === "history") {
        columns.value = columnsForHistory;
      }
    });

    return {
      columns,
      ...toRefs(state),
      onSelectChange,
      tableHeight,
      gotoDetail,
      openUploadDrawer,
      appealOperation,
    };
  },
});
